import Link from "next/link";

export type WebsiteFooterColItem = {
  label: string;
  url: string;
  target?: string;
};

type WebsiteFooterColProps = {
  title: string;
  items: WebsiteFooterColItem[];
};

const WebsiteFooterCol = ({ title, items }: WebsiteFooterColProps) => {
  return (
    <>
      <h3 className="font-matter text-md font-semibold text-[#292b29]">{title}</h3>
      <ul role="list" className="mt-4 space-y-2">
        {items?.map((item) => {
          return (
            <li key={item.label}>
              <Link
                href={item.url}
                target={item.target}
                prefetch={false}
                className="cursor-pointer font-matter text-[#222] hover:underline"
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default WebsiteFooterCol;

import { forwardRef } from "react";
import dynamic from "next/dynamic";
import { ChevronDown } from "lucide-react";
import cn from "classnames";

const AccordionItemComp = dynamic(() => import("@radix-ui/react-accordion").then((mod) => mod.Item), { ssr: false });
const AccordionHeaderComp = dynamic(() => import("@radix-ui/react-accordion").then((mod) => mod.Header), {
  ssr: false
});
const AccordionContentComp = dynamic(() => import("@radix-ui/react-accordion").then((mod) => mod.AccordionContent), {
  ssr: false
});
export const Accordion = dynamic(() => import("@radix-ui/react-accordion").then((mod) => mod.Root), {
  ssr: false
});
const AccordionTriggerComp = dynamic(() => import("@radix-ui/react-accordion").then((mod) => mod.Trigger), {
  ssr: false
});

export const AccordionItem = forwardRef<
  React.ElementRef<typeof AccordionItemComp>,
  React.ComponentPropsWithoutRef<typeof AccordionItemComp>
>(({ className, ...props }, ref) => (
  <AccordionItemComp
    // @ts-ignore
    ref={ref}
    className={cn("py-3 last:border-none", className)}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

export const AccordionTrigger = forwardRef<
  React.ElementRef<typeof AccordionTriggerComp>,
  React.ComponentPropsWithoutRef<typeof AccordionTriggerComp>
>(({ className, children, ...props }, ref) => (
  <AccordionHeaderComp className="flex">
    <AccordionTriggerComp
      // @ts-ignore
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between font-medium text-gray-600  transition-all data-[state=open]:text-primary-700 sm:text-lg [&[data-state=open]>svg]:rotate-180",
        className
      )}
      {...props}
    >
      {children}
      <ChevronDown strokeWidth={3.5} className="h-5 w-5 transition-transform duration-300" />
    </AccordionTriggerComp>
  </AccordionHeaderComp>
));
AccordionTrigger.displayName = AccordionTriggerComp.displayName;

export const AccordionContent = forwardRef<
  React.ElementRef<typeof AccordionContentComp>,
  React.ComponentPropsWithoutRef<typeof AccordionContentComp>
>(({ className, children, ...props }, ref) => (
  <AccordionContentComp
    // @ts-ignore
    ref={ref}
    className={cn(
      "overflow-hidden text-sm  transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down sm:text-base",
      className
    )}
    {...props}
  >
    {children}
  </AccordionContentComp>
));
AccordionContent.displayName = AccordionContentComp.displayName;

"use client";

import clsx from "clsx";
import { ArrowRight } from "lucide-react";
import Image from "next/image"; // Importing the Image component from Next.js
import Link from "next/link";
import { useParams, usePathname } from "next/navigation";
import { forwardRef } from "react";
import useScroll from "hooks/useScroll";

import MaxWidthWrapper from "components/Tiles/commonComponents/MaxWidthWrapper";
import { cn } from "lib/utils";

import PromoBar from "components/PromoBar";
import {
  PAGE_QUIZ_CTA_BUTTON_MAP,
  PAGE_QUIZ_DEFAULT_CTA_BUTTON,
  PAGE_QUIZ_DEFAULT_CTA_BUTTON_SPANISH
} from "components/Quiz/utils";
import { Button } from "components/ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle
} from "components/ui/navigationMenu";
import Logo from "components/logo";
import useUIState from "hooks/useUIState";
import { APP_LANGUAGES, APP_STRINGS_LABELS, getAppStringForLanguage } from "utils/language";
import MobileMenu from "./mobile-menu";
import {
  GLOBAL_PROMOBAR_ICON,
  GLOBAL_PROMOBAR_SUBTITLE,
  GLOBAL_PROMOBAR_TITLE,
  OverlayedWebsiteNavPages,
  SHOW_GLOBAR_PROMOBAR,
  disableStickyWebsiteNavPages,
  navItems,
  spanishNavItems
} from "./utils";
export default function Nav() {
  const { domain = "soldave.com" } = useParams() as { domain: string };
  const scrolled = useScroll(5);
  const mobilecta = useScroll(200);
  const pathname = usePathname();
  const { uiState } = useUIState();

  const isOverlayed = pathname && OverlayedWebsiteNavPages.includes(pathname);
  const isStickyNav = !pathname || !disableStickyWebsiteNavPages.includes(pathname);
  const isSpanishLanguage = uiState?.websiteTileLanguage === APP_LANGUAGES.SPANISH;
  const language = isSpanishLanguage ? APP_LANGUAGES.SPANISH : APP_LANGUAGES.ENGLISH;
  const quizCtaButtonInfo =
    pathname && PAGE_QUIZ_CTA_BUTTON_MAP[pathname]
      ? PAGE_QUIZ_CTA_BUTTON_MAP[pathname]
      : isSpanishLanguage
        ? PAGE_QUIZ_DEFAULT_CTA_BUTTON_SPANISH
        : PAGE_QUIZ_DEFAULT_CTA_BUTTON;

  const isDisabled = uiState?.disabledDefaultWebsiteHeaderFooter;
  const finalNavItems = isSpanishLanguage ? spanishNavItems : navItems;

  if (isDisabled) return null;
  return (
    <>
      <div
        className={cn(`relative inset-x-0  top-0 z-40 w-full`, {
          notsticky: !isStickyNav, // Provide a default empty string if pathname is null
          sticky: isStickyNav,
          "shadow-[#0000001a 0px 1px 0px 0px] border-b-[1px] bg-white": scrolled,
          "border-b border-t-0 border-transparent": !scrolled
        })}
      >
        {SHOW_GLOBAR_PROMOBAR && (
          <PromoBar
            icon={GLOBAL_PROMOBAR_ICON}
            title={GLOBAL_PROMOBAR_TITLE}
            subTitle={GLOBAL_PROMOBAR_SUBTITLE}
            className="relative"
          />
        )}
        <MaxWidthWrapper className="!max-w-[1680px]">
          <div className="flex h-20 ">
            <div className="flex w-full items-center space-x-2 text-primary-700 xl:space-x-2">
              <div className="flex">
                <Link href={isSpanishLanguage ? "/es" : "/"} title="Sold Ave">
                  <Logo />
                </Link>
              </div>
              <div className="flex-grow items-center justify-center">
                <NavigationMenu className="relative z-10 hidden  lg:flex">
                  <NavigationMenuList className="flex w-full justify-between">
                    {finalNavItems.map(({ name, slug, dropdown, items, singleColumn, id }) => (
                      <NavigationMenuItem key={id} className={slug === "partner" ? "ml-auto justify-end self-end" : ""}>
                        {dropdown ? (
                          <>
                            <NavigationMenuTrigger
                              className={cn(
                                "z-10 rounded-lg px-4 py-1.5 font-matter text-[14px]  capitalize transition-colors ease-out ",
                                {
                                  "text-[#717171]": !isOverlayed || scrolled,
                                  "text-white focus:text-[#717171]": isOverlayed && !scrolled
                                }
                              )}
                            >
                              {name}
                            </NavigationMenuTrigger>
                            {items && ( // Check if items exists before mapping
                              <NavigationMenuContent
                                className={cn(
                                  ""
                                  // other styles...
                                )}
                              >
                                <ul
                                  className={clsx(
                                    "p-4",
                                    singleColumn
                                      ? "grid w-[300px] gap-3 p-4 md:w-[300px]"
                                      : "one m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[500px] sm:grid-cols-[0.75fr_1fr]"
                                  )}
                                >
                                  {items.map(({ name, slug, iconUrl, id, description }) => (
                                    <ListItem
                                      singleColumn={!!singleColumn}
                                      key={id}
                                      href={`/${slug}`}
                                      title={name}
                                      iconUrl={iconUrl}
                                    >
                                      {singleColumn ? description : null}
                                    </ListItem>
                                  ))}
                                </ul>
                              </NavigationMenuContent>
                            )}
                          </>
                        ) : (
                          <NavigationMenuLink
                            className={cn(navigationMenuTriggerStyle(), {
                              "cursor-pointer text-[#717171]": !isOverlayed || scrolled,
                              "text-white": isOverlayed && !scrolled,
                              "active-nav-item font-medium text-[#222] ": pathname === `/${slug}` // Here's the check for active nav item
                            })}
                            asChild
                          >
                            <Link
                              href={domain === "soldave.com" ? `/${slug}` : `https://soldave.com/${slug}`}
                              prefetch={false}
                              passHref
                            >
                              {" "}
                              {name}
                            </Link>
                          </NavigationMenuLink>
                        )}
                      </NavigationMenuItem>
                    ))}
                  </NavigationMenuList>
                </NavigationMenu>
              </div>

              <div className="hidden lg:block lg:space-x-2 xl:space-x-4">
                <a
                  href={`tel:${getAppStringForLanguage(APP_STRINGS_LABELS.SOLDAVE_NUMBER_E164, language)}`}
                  className={cn("mr-2 hidden text-[16px] underline xl:inline", {
                    "text-[#717171]": !isOverlayed || scrolled,
                    "text-white": isOverlayed && !scrolled
                  })}
                  title={getAppStringForLanguage(APP_STRINGS_LABELS.CALL_SOLDAVE, language)}
                >
                  {getAppStringForLanguage(APP_STRINGS_LABELS.SOLDAVE_NUMBER, language)}
                </a>
                <Button asChild variant="brand" size="default">
                  <Link
                    href={`/${getAppStringForLanguage(APP_STRINGS_LABELS.QUIZ, language)}/${quizCtaButtonInfo.slug}`}
                  >
                    <div className="flex flex-row items-center">
                      <span className="mr-2 text-sm font-medium">{quizCtaButtonInfo.ctaText}</span>{" "}
                      <ArrowRight size={12} strokeWidth={2} />
                    </div>
                  </Link>
                </Button>
              </div>
              <div className="block flex-none lg:hidden">
                <MobileMenu />
              </div>
            </div>
          </div>
        </MaxWidthWrapper>
      </div>
      {mobilecta && (
        <div className={`slideUpContainer lg:hidden ${mobilecta ? "show" : ""}`}>
          <Button asChild variant="slideUp">
            <Link
              className="w-full"
              href={`/${getAppStringForLanguage(APP_STRINGS_LABELS.QUIZ, language)}/${quizCtaButtonInfo.slug}`}
            >
              {quizCtaButtonInfo.ctaText}
            </Link>
          </Button>
        </div>
      )}
    </>
  );
}

const ListItem = forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & {
    iconUrl?: string;
    singleColumn: boolean;
  }
>(({ className, title, iconUrl, singleColumn, ...props }, ref) => (
  <li>
    <NavigationMenuLink asChild>
      <Link
        href={props.href || ""}
        ref={ref}
        className={cn(
          " inline-flex w-full max-w-[310px] !cursor-pointer select-none items-center space-y-1 rounded-lg p-3 leading-none no-underline outline-none transition-colors hover:bg-[#f1efe2] hover:text-[#222] ",
          className,
          singleColumn ? "flex-row items-center gap-2" : "flex-col gap-1"
        )}
        {...props}
      >
        {singleColumn ? (
          <>
            <div className="flex flex-col items-start justify-start gap-1 px-2 py-2">
              {iconUrl ? (
                <Image
                  src={iconUrl}
                  alt={`${title} icon`}
                  width={20}
                  height={20}
                  className="inline-flex flex-shrink-0 self-start"
                />
              ) : null}
              <div className="text-left text-sm leading-none">{title}</div>
            </div>
          </>
        ) : (
          <>
            <div className="hover:text-accent-foreground focus:text-accent-foreground inline-flex w-full items-center leading-none no-underline outline-none transition-colors hover:bg-[#f1efe2] focus:bg-[#f1efe2]">
              {iconUrl ? (
                <Image
                  src={iconUrl}
                  alt={`${title} icon`}
                  width={20}
                  height={20}
                  className="mr-2 h-[40px] w-[40px] flex-shrink-0  rounded-lg bg-[#f1efe2] p-2"
                />
              ) : null}
              <div className="text-sm leading-none">{title}</div>
            </div>
          </>
        )}
      </Link>
    </NavigationMenuLink>
  </li>
));
ListItem.displayName = "ListItem";

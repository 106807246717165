import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center  whitespace-nowrap font-matter font-normal  rounded-full text-[16px]  transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "transition-colors  text-lg bg-brand  text-[#ffffff]  hover:bg-[#004733]  active:bg-primary-900",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border shadow-sm  text-brand border-brand bg-transparent hover:bg-brand dark:text-white",
        outlineWhite: "border shadow-sm  text-white border-white bg-transparent hover:bg-brandBetter hover:text-white",
        brand: "transition-colors bg-brand  text-[#ffffff]  hover:bg-[#004733]  active:bg-primary-900",
        primary:
          "transition-colors bg-[#171717] dark:bg-[#ededed] text-[#ffffff] dark:text-[#0a0a0a] hover:bg-[#383838] dark:hover:bg-[#cccccc] active:bg-primary-900",
        actionpositive: "bg-[#ffffff5e] hover:bg-[#0000000f] hover:text-primary-700",
        actionnegative: "bg-[#ffffff5e] hover:bg-[#0000000f] hover:text-primary-700",
        secondary: "bg-[#183837] text-white hover:bg-[#183837]/80",
        ghost: "hover:bg-skeleton hover:text-accent-foreground",
        link: "text-brandlight underline-offset-2 hover:underline link-with-chevron",
        linkIcon: "text-brandlight underline-offset-2 hover:underline link-with-chevron",
        linkEdit: "text-brandlight underline-offset-2 hover:underline",
        brandLink: "text-gold underline-offset-4 hover:underline link-with-chevron",
        brandBetter: "text-white bg-brandBetter hover:bg-[#004733]",
        selected: "bg-brandlight text-white",
        disabled: "text-white disabled:cursor-not-allowed",
        "brand-2": "transition-colors font-medium  bg-brand  text-[#ffffff]  hover:bg-[#004733]  active:bg-primary-900",
        linkIconArrow: "text-gold underline-offset-2 hover:underline corner-right-down-icon",
        icon: " border shadow-sm text-white border-[#10101899] bg-[#10101899] hover:bg-white hover:text-brand",
        slideUp: "slideUpButton",
        started:
          " animate-fade-in  transition-colors  bg-brand  text-[#ffffff]  hover:bg-[#004733]  active:bg-primary-900"
      },
      size: {
        default: "h-10 px-4 py-2",
        defaultLink: "h-10  !leading-none",
        smallLink: "h-10 !font-normal  !text-base !leading-none",
        checkout: "w-full h-16 min-h-[50px] !font-semibold",
        sm: "h-9 px-3",
        lg: "h-11 px-6",
        xl: "h-12 px-10 ",
        xlb: "h-14 py-4 px-10 w-full lg:w-auto my-4 ",
        better: "h-16 px-10  w-full lg:w-auto my-4",
        betterx: "h-12 px-10 mx-4  w-full lg:w-auto my-4",
        big: "h-11 rounded-2xl px-8 w-full",
        xxl: " !text-md",
        txt: " !text-sm !leading-none",
        iconSm: "h-6 w-6",
        icon: "h-10 w-10",
        iconBig: "h-14 w-14",
        rounded: "h-10 w-10 rounded-full",
        roundedBig: "h-16 w-16 rounded-full"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export enum ButtonVariantTypes {
  DEFAULT = "default",
  DESTRUCTIVE = "destructive",
  OUTLINE = "outline",
  BRAND = "brand",
  SECONDARY = "secondary",
  GHOST = "ghost",
  LINK = "link",
  STARTED = "started",
  DIALOG = "dialog" // This can be used to trigger the open dialog button, we can use rename this or use an existing variant as needed
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  hoverColor?: string; // New prop
  icon?: React.ReactNode; // Add a new prop for the icon
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, hoverColor, children, icon, ...props }, ref) => {
    const Comp = asChild ? "span" : "button";
    const dynamicHover = hoverColor ? `hover:text-${hoverColor}` : ""; // Compute dynamic hover class
    return (
      <Comp className={cn(buttonVariants({ variant, size }), dynamicHover, className)} ref={ref} {...props}>
        {children}
        {icon && <span className="icon-wrapper">{icon}</span>}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };

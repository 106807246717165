import Image from "next/image";
import { cn } from "lib/utils";

export const Logo = ({ classNameContainer, isCollapsed }: { classNameContainer?: string; isCollapsed?: boolean }) => {
  return (
    <div
      className={cn(
        "relative z-[999999999999] flex  h-[40px] items-center gap-2",
        isCollapsed ? "w-[40px] group-hover/nav:w-[100px] group-hover/nav:lg:w-[120px]" : "w-[100px] lg:w-[120px]",
        classNameContainer
      )}
    >
      <Image
        src="/logo-full.svg"
        alt="soldave logo"
        fill
        className={isCollapsed ? "hidden group-hover/nav:block" : "block"}
      />
      <Image
        src="/logo-small.svg"
        alt="soldave logo"
        fill
        className={isCollapsed ? "block group-hover/nav:hidden" : "hidden"}
      />
    </div>
  );
};

export default Logo;

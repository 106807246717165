"use client";
import { Mail } from "lucide-react";
import Logo from "components/logo";
import useUIState from "hooks/useUIState";
import { APP_LANGUAGES, APP_STRINGS_LABELS, getAppStringForLanguage } from "utils/language";
import SocialIcons from "./SocialIcons";
import WebsiteFooterCol, { WebsiteFooterColItem } from "./WebsiteFooterCol";
import { FOOTER_PROPS, FOOTER_PROPS_SPANISH } from "./utils";

type FooterProps = {
  description: string;
  social: { icon: string; url: string }[];
  columns: { title: string; items: WebsiteFooterColItem[] }[];
};

const WebsiteFooter = () => {
  const { uiState } = useUIState();
  const isDisabled = uiState?.disabledDefaultWebsiteHeaderFooter;

  const finalFooterProps: FooterProps =
    uiState?.websiteTileLanguage === APP_LANGUAGES.SPANISH ? FOOTER_PROPS_SPANISH : FOOTER_PROPS;

  const tileLanguage = uiState?.websiteTileLanguage || APP_LANGUAGES.ENGLISH;

  const { description, social, columns } = finalFooterProps;

  if (isDisabled) return null;
  return (
    <footer className=" z-10 -mt-1  flex flex-grow  items-center justify-center bg-[#FFFDFA] py-8 @container ">
      <section className="mx-auto mt-6 flex w-full flex-col px-1 @2xl:px-12 @3xl:mt-12 @3xl:max-w-[1280px] @3xl:flex-row @3xl:justify-between  @3xl:px-12 @3xl:pt-12 @5xl:max-w-[1680px] @5xl:px-24">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-8xl mx-auto w-full px-4 py-12 @2xl:px-0 @3xl:py-0 @5xl:px-0">
          <div className="grid grid-cols-2 justify-center gap-8 @3xl:grid-cols-7">
            <div className="col-span-2 @3xl:col-span-3 @3xl:pr-16 @5xl:pr-24">
              <Logo />
              <p className="mt-5 pr-5 text-base font-normal leading-6 text-[#565d5a]">{description}</p>
              <article className="mb-12 flex-1 @2xl:mb-0"></article>
            </div>

            {columns?.map((column, index) => (
              <div className="@3xl:col-span-1" key={index}>
                <WebsiteFooterCol title={column.title} items={column.items} />
              </div>
            ))}
          </div>

          <div className="mb-2 items-center pb-4 pt-8 text-sm font-normal @3xl:flex @3xl:items-center @3xl:justify-between">
            <div className="flex space-x-2">
              <p className="items-center pb-8 text-center text-sm text-[#222]   @3xl:pb-0">
                &copy; 2024 Sold Ave, Inc.
              </p>
              <span className=" pb-8 text-lg leading-4 text-[#222]   @3xl:pb-0">·</span>
              <a
                href="/terms-of-service"
                title="Terms"
                className="cursor-pointer pb-8 text-center text-[#222222]  hover:underline @3xl:pb-0"
              >
                {getAppStringForLanguage(APP_STRINGS_LABELS.Terms, tileLanguage)}
              </a>
              <span className="pb-8 text-lg leading-4 text-[#222222]  @3xl:pb-0">·</span>
              <a
                href="/privacy-policy"
                title="Privacy"
                className="cursor-pointer pb-8 text-center text-[#222]  hover:underline @3xl:pb-0"
              >
                {getAppStringForLanguage(APP_STRINGS_LABELS.Privacy, tileLanguage)}
              </a>
            </div>
            <div className="flex items-center space-x-6   fill-[#222] text-[#222]  @3xl:justify-center">
              {social?.map((item) => (
                <a
                  key={item.icon}
                  title={item.icon}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-[#222]  hover:text-gray-500"
                >
                  <span className="sr-only">{item.icon}</span>
                  <SocialIcons icon={item.icon} className="h-5 w-5 fill-[#222222] " aria-hidden="true" />
                </a>
              ))}
              <span className="ml-2 hidden text-[#222]  @3xl:flex">
                <Mail className="mr-2 inline-block text-[#222] " aria-hidden="true" />
                hello@soldave.com
              </span>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default WebsiteFooter;

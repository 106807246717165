/*
 * Path: /components/TileComponents/PromoBar/index.tsx
 */
import clsx from "clsx";
import Icon from "components/Icon";

type PromoBarProps = {
  icon?: string;
  title: string;
  subTitle: string;
  titleTag?: keyof JSX.IntrinsicElements;
  className?: string;
};

const PromoBar = ({ icon, title, titleTag = "p", subTitle, className }: PromoBarProps) => {
  const TitleTag = titleTag;

  return (
    <div className={clsx("fixed left-0 top-0 z-50 h-11 w-full bg-[#FF9788] text-[#fff] @2xl:h-10", className)}>
      <div className="container mx-auto flex h-full items-center justify-center px-6 @2xl:px-12">
        {icon && <Icon name={icon as any} className="mr-3 h-5 w-auto text-[#fff]" />}
        <div className="flex flex-col items-center gap-x-2 md:flex-row">
          <TitleTag className="text-center text-[13px] font-semibold">{title}</TitleTag>
          <p className="text-center text-xs">{subTitle}</p>
        </div>
      </div>
    </div>
  );
};

export default PromoBar;

export const FOOTER_PROPS = {
  description: "We provide fast, fair solutions for homeowners looking to sell their properties.",
  social: [
    { url: "https://www.facebook.com/profile.php?id=61563170173913", icon: "Facebook" },
    { url: "https://x.com/SoldAvenue", icon: "Twitter" },
    { url: "https://www.instagram.com/soldavenue/", icon: "Instagram" },
    { url: "https://linkedin.com/company/sold-ave", icon: "LinkedIn" },
    { url: "https://www.youtube.com/@SoldAvenue", icon: "YouTube" }
  ],
  columns: [
    {
      title: "Sell Your Property",
      items: [
        { url: "/how-it-works", label: "How It Works" },
        { url: "/home-seller-faq", label: "FAQ" },
        { url: "/reviews", label: "Reviews" },
        { url: "/about-us", label: "About Us" }
      ]
    },
    {
      title: "Offer Structures",
      items: [
        { url: "/offer-structures/cash-offer", label: "Cash Offer" },
        { url: "/offer-structures/novation", label: "Novation" },
        { url: "/offer-structures/subject-to", label: "Subject-To" },
        { url: "/offer-structures/owner-financing", label: "Owner Financing" },
        { url: "/offer-structures/renovation-profit-sharing", label: "Renovation Profit Sharing" }
      ]
    },
    {
      title: "Situations",
      items: [
        { url: "/situations/distressed-property", label: "Distressed Property" },
        { url: "/situations/inherited-property", label: "Inherited Property" },
        { url: "/situations/challenging-tenants", label: "Challenging Tenants" },
        { url: "/situations/divorce", label: "Divorce" },
        { url: "/situations/foreclosure", label: "Foreclosure" },
        { url: "/situations/vacant-land", label: "Vacant Land" }
      ]
    },
    {
      title: "Partner With Us",
      items: [
        { url: "/investors", label: "For Investors" },
        { url: "/realtors", label: "For Realtors" },
        { url: "/wholesalers", label: "For Wholesalers" }
      ]
    }
  ]
};

export const FOOTER_PROPS_SPANISH = {
  description: "Ofrecemos soluciones rápidas y justas para propietarios que buscan vender sus propiedades.",
  social: [
    { url: "https://www.facebook.com/profile.php?id=61563170173913", icon: "Facebook" },
    { url: "https://x.com/SoldAvenue", icon: "Twitter" },
    { url: "https://www.instagram.com/soldavenue/", icon: "Instagram" },
    { url: "https://linkedin.com/company/sold-ave", icon: "LinkedIn" },
    { url: "https://www.youtube.com/@SoldAvenue", icon: "YouTube" }
  ],
  columns: [
    {
      title: "Vende Tu Propiedad",
      items: [
        { url: "/es/como-funciona", label: "Cómo Funciona" },
        { url: "/es/preguntas-frecuentes", label: "Preguntas Frecuentes" },
        { url: "/es/resenas", label: "Reseñas" },
        { url: "/es/sobre-nosotros", label: "Sobre Nosotros" }
      ]
    },
    {
      title: "Estructuras de Oferta",
      items: [
        { url: "/es/estructuras-de-oferta/oferta-en-efectivo", label: "Oferta en Efectivo" },
        { url: "/estructuras/proteccion-de-patrimonio", label: "Protección de Patrimonio" },
        { url: "/es/estructuras-de-oferta/sujeto-a", label: "Sujeto a" },
        { url: "/es/estructuras-de-oferta/financiamiento-del-propietario", label: "Financiamiento del Propietario" },
        { url: "/es/estructuras-de-oferta/compartir-ganancias-renovacion", label: "Compartir Ganancias de Renovación" }
      ]
    },
    {
      title: "Situaciones",
      items: [
        { url: "/es/situaciones/propiedad-en-dificultades", label: "Propiedad en Dificultades" },
        { url: "/es/situaciones/propiedad-heredada", label: "Propiedad Heredada" },
        { url: "/es/situaciones/inquilinos-problematicos", label: "Inquilinos Problemáticos" },
        { url: "/es/situaciones/divorcio", label: "Divorcio" },
        { url: "/es/situaciones/ejecucion-hipotecaria", label: "Ejecución Hipotecaria" },
        { url: "/es/situaciones/terreno-baldio", label: "Terreno Baldío" }
      ]
    },
    {
      title: "Asociese Con Nosotros",
      items: [
        { url: "/es/inversionistas", label: "Inversionistas" },
        { url: "/es/agentes-inmobiliarios", label: "Agentes Inmobiliarios" },
        { url: "/es/mayoristas", label: "Mayoristas" }
      ]
    }
  ]
};
